<template>
  <div class="partners">
    <h1>Partners</h1>

    <div class="logos">
      <a href="https://www.brugge.be" target="_blank"><img src="@/assets/images/partners/brugge.png"></a>
      <a href="http://www.dumarent.be" target="_blank"><img src="@/assets/images/partners/dumarent.png"></a>
      <a href="https://www.paintfactory.be" target="_blank"><img src="@/assets/images/partners/paintfactory.png"></a>
      <a href="https://www.pittmanseafoods.com/nl" target="_blank"><img src="@/assets/images/partners/pittman.png"></a>
      <a href="https://www.snuffel.one/nl/home" target="_blank"><img src="@/assets/images/partners/snuffel.png"></a>
      <a href="https://www.brugsezot.be/nl/home" target="_blank"><img src="@/assets/images/partners/zot.png"></a>
      <a href="https://www.wallfar.com" class="wallfar" target="_blank"><img src="@/assets/images/partners/wallfar.png"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners'
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  
  .partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    max-width: 1400px;
    margin: auto;

    h1 {
      color: $red;
      font-family: "DIN-Condensed-Bold", sans-serif;
    }
  }

  .logos {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    a {
      width: 45%;
      height: 100px;
      max-width: 200px;
      margin: 4px 0;

      @media (min-height: 650px) {
        height: 120px;
        margin: 10px 0;
      }

      @include tablet {
        margin: 10px;
      }

      &.wallfar {
        padding: 0 30px;
        width: 160px;
        height: 65px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
</style>